import config from 'config';
import { authHeader } from '../_helpers';

export const quizService = {
    get,
    getAll,
    update,
    add,
    _delete,
    markQuiz,
    markAnswer,
    registerTeam,
    getWithTeam
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/quiz/`, requestOptions).then(handleResponse);
}


function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/quiz/${id}`, requestOptions).then(handleResponse);
}

function getWithTeam(id, teamId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/quiz/${id}?teamId=${teamId}`, requestOptions).then(handleResponse);
}


function add(quiz) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(quiz)
    };

    return fetch(`${config.apiUrl}/quiz/create`, requestOptions).then(handleResponse);
}

function update(quiz) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(quiz)
    };

    return fetch(`${config.apiUrl}/quiz/${quiz.id}`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/quiz/${id}`, requestOptions).then(handleResponse);
}

function markQuiz(quiz) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(quiz)
    };

    return fetch(`${config.apiUrl}/quiz/mark`, requestOptions).then(handleResponse);
}

function markAnswer(answer) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(answer)
    };

    return fetch(`${config.apiUrl}/quiz/answer`, requestOptions).then(handleResponse);
}

function registerTeam(team) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(team)
    };

    return fetch(`${config.apiUrl}/quiz/register`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}