var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-header" }, [
    _c("div", { staticClass: "header-container" }, [
      _c(
        "div",
        { staticClass: "header-left" },
        [
          _c("h3", { staticClass: "header-brand" }, [
            _vm._v("ThePubHub Admin-Area")
          ]),
          _vm._v(" "),
          _c("caution-element")
        ],
        1
      ),
      _vm._v(" "),
      _vm.loggedIn
        ? _c("div", { staticClass: "header-right" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-danger right-links",
                attrs: { href: "/login" }
              },
              [_vm._v("Logout")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-info right-links",
                attrs: { href: "/user/" + _vm.userData._id }
              },
              [_vm._v("Edit User")]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-681d8f34", { render: render, staticRenderFns: staticRenderFns })
  }
}