var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Add Embedded Iframe")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.handleSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "url" } }, [_vm._v("URL")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.embedded.url,
                expression: "embedded.url"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.submitted && _vm.errors.has("url") },
            attrs: { type: "text", name: "url" },
            domProps: { value: _vm.embedded.url },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.embedded, "url", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.submitted && _vm.errors.has("url")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.first("url")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "buttontext" } }, [
            _vm._v("Button Text")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.embedded.buttontext,
                expression: "embedded.buttontext"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.submitted && _vm.errors.has("buttontext")
            },
            attrs: { type: "text", name: "buttontext" },
            domProps: { value: _vm.embedded.buttontext },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.embedded, "buttontext", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.submitted && _vm.errors.has("buttontext")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.first("buttontext")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-check" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.embedded.isActive,
                expression: "embedded.isActive"
              }
            ],
            staticClass: "form-check-input",
            class: {
              "is-invalid": _vm.submitted && _vm.errors.has("isActive")
            },
            attrs: { type: "checkbox", name: "isActive" },
            domProps: {
              checked: Array.isArray(_vm.embedded.isActive)
                ? _vm._i(_vm.embedded.isActive, null) > -1
                : _vm.embedded.isActive
            },
            on: {
              change: function($event) {
                var $$a = _vm.embedded.isActive,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.embedded, "isActive", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.embedded,
                        "isActive",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.embedded, "isActive", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-check-label", attrs: { for: "isActive" } },
            [_vm._v("Is Active?")]
          ),
          _vm._v(" "),
          _vm.submitted && _vm.errors.has("isActive")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.first("isActive")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.status.adding }
              },
              [_vm._v("Submit")]
            ),
            _vm._v(" "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status.adding,
                  expression: "status.adding"
                }
              ],
              attrs: {
                src:
                  "data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              }
            }),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "btn btn-link", attrs: { to: "/" } },
              [_vm._v("Cancel")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.all.error
          ? _c("span", { staticClass: "text-danger" }, [
              _vm._v("ERROR: " + _vm._s(_vm.all.error.error))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("br")
      ]
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "current-embedded" }, [
      _vm.latestEmbedded
        ? _c("div", { staticClass: "clearfix" }, [
            _c("h4", [_vm._v("Current iframe")]),
            _vm._v(" "),
            _c("dl", { staticClass: "float-left" }, [
              _c("dt", [_vm._v("URL:")]),
              _c("dd", [
                _c("a", { attrs: { href: _vm.latestEmbedded.url } }, [
                  _vm._v(_vm._s(_vm.latestEmbedded.url))
                ])
              ]),
              _vm._v(" "),
              _c("dt", [_vm._v("Button Text:")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.latestEmbedded.buttontext))])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-danger float-right",
                on: { click: _vm.deleteAction }
              },
              [_vm._v("Delete")]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ffe8f296", { render: render, staticRenderFns: staticRenderFns })
  }
}