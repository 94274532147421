import config from 'config';
import { authHeader } from '../_helpers';

export const embeddedService = {
    get,
    getLatest,
    update,
    add,
    deleteAll
};

function get() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/embedded`, requestOptions).then(handleResponse);
}

function getLatest() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/embedded/latest`, requestOptions).then(handleResponse);
}

function add(embedded) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(embedded)
    };

    return fetch(`${config.apiUrl}/embedded/create`, requestOptions).then(handleResponse);
}

function update(embedded) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(embedded)
    };

    return fetch(`${config.apiUrl}/embedded/${embedded.id}`, requestOptions).then(handleResponse);
}

function deleteAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/embedded/deleteAll`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}