<style scoped>
    .admin-header {
        height: 50px;
        background-color: #e9ecef;
        display: flex;
        flex-direction: column;
    }

    .header-container {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
    }
    .header-left {
        padding: 5px 0 0 10px;
        width: 50%;
        
    }

    .header-right {
        margin: 5px 20px 0 0;
        flex-grow: 1;
    }

    .right-links {
        margin-right: 10px;
        float: right;
    }

    .header-brand {
        float: left;
    }
</style>
<template>
    <div class="admin-header">
        <div class="header-container">
            <div class="header-left">
                <h3 class="header-brand">ThePubHub Admin-Area</h3>
                <caution-element></caution-element>
            </div>
            <div class="header-right" v-if="loggedIn">
                <a href="/login" class="btn btn-danger right-links">Logout</a>
                <a v-bind:href="`/user/${userData._id}`" class="btn btn-info right-links">Edit User</a>
            </div>
        </div>
    </div>
</template>
<script>
import CautionElement from '../caution/CautionElement';
export default {
    name: 'header-element',
    props: {
        loggedIn: String,
    },
    data: function() {
        return {
            userData: JSON.parse(JSON.parse(this.loggedIn))
        }
    },
    components: {
        'caution-element': CautionElement
    }
}
</script>