import { configService } from '../_services';

const state = {
    config: {},
    all: {}
}

const actions = {
    get({ commit }) {
        commit('getRequest');

        configService.get()
            .then(
                config => commit('getSuccess', config.toString()),
                error => commit('getFailure', error)
            );
    },

    save({ commit }, config) {
        commit('saveRequest');

        configService.save(config)
            .then(
                config => commit('saveSuccess', config),
                error => commit('saveFailure', error)
            );
    }

};

const mutations = {
    getRequest(state) {
        state.all = { loading: true };
    },
    getSuccess(state, config) {
        state.config = config.toString();
    },
    getFailure(state, error) {
        console.log(error)
        state.all = {error: error};
    },
    saveRequest(state) {
        state.all = { loading: true };
    },
    saveSuccess(state, config) {
        state.config = config;
    },
    saveFailure(state, error) {
        state.all = {error};
    },
};

export const config = {
    namespaced: true,
    state,
    actions,
    mutations
};