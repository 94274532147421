var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.users.loading ? _c("em", [_vm._v("Loading users...")]) : _vm._e(),
    _vm._v(" "),
    _vm.users.error
      ? _c("span", { staticClass: "text-danger" }, [
          _vm._v("ERROR: " + _vm._s(_vm.users.error))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h3", [_vm._v("Users")]),
    _vm._v(" "),
    _vm.users.items
      ? _c(
          "ul",
          _vm._l(_vm.users.items, function(user) {
            return _c("li", { key: user.id, staticClass: "list-item" }, [
              _vm._v(
                "\n            " +
                  _vm._s(user.firstName + " " + user.lastName) +
                  "\n            "
              ),
              user.deleting
                ? _c("span", [_c("em", [_vm._v(" - Deleting...")])])
                : user.deleteError
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(" - ERROR: " + _vm._s(user.deleteError))
                  ])
                : _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-danger float-right",
                        on: {
                          click: function($event) {
                            return _vm.deleteUser(user.id)
                          }
                        }
                      },
                      [_vm._v("Delete")]
                    )
                  ])
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a0da1eda", { render: render, staticRenderFns: staticRenderFns })
  }
}