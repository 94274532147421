var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-chrome" }, [
    _c(
      "header",
      [
        _c("header-element", {
          attrs: { loggedIn: JSON.stringify(_vm.loggedIn) }
        }),
        _vm._v(" "),
        _vm.alert.message
          ? _c("div", { class: "alert " + _vm.alert.type }, [
              _vm._v(_vm._s(_vm.alert.message))
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("main", { staticClass: "admin-main" }, [
      _vm.loggedIn
        ? _c(
            "nav",
            { staticClass: "admin-sidebar" },
            [_c("menu-element", { attrs: { loggedIn: _vm.loggedIn } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("article", { staticClass: "admin-page" }, [_c("router-view")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7e7f006c", { render: render, staticRenderFns: staticRenderFns })
  }
}