import { videoService } from '../_services';
import { router } from '../_helpers';


const state = {
    all: {},
    status: {},
    latestVideo: {},
    adding: false
};

const actions = {
    getLatest({ commit }) {
        commit('getRequest');

        videoService.getLatest()
            .then(
                latestVideo => {
                    latestVideo.youtubeInfo = 
                    commit('getSuccess', latestVideo);
                },
                error => commit('getFailure', error)
            );
    },

    add({ commit }, video) {
        commit('addRequest', video);
        state.status = { adding: true };
        videoService.add(video)
            .then(
                video => {
                    commit('addSuccess', video);
                },
                error => commit('addFailure', { error: error.toString() })
            );
    },

    delete({ commit }, id) {
        commit('deleteRequest', id);

        videoService.delete(id)
            .then(
                video => commit('deleteSuccess', id),
                error => commit('deleteFailure', { id, error: error.toString() })
            );
    },

    deleteAll({ commit }) {
        videoService.deleteAll()
            .then(
                () => commit('deleteAllSuccess'),
                error => commit('deleteAllFailure', { error: error.toString() })
            );
    }
};

const mutations = {
    getRequest(state) {
        state.all = { loading: true };
    },
    getSuccess(state, latestVideo) {
        state.latestVideo = latestVideo[0];
    },
    getFailure(state, error) {
        state.all = { error };
    },
    addSuccess(state, video) {
        state.all = { items: video };
        state.status = {};
        //state.latestVideo = video;
    },
    addRequest(state, video) {

    },
    addFailure(state, error) {
        state.status = {};
        state.all = { error: error };
        state.error = { error };
    },
    deleteRequest(state, id) {
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(video =>
            video.id === id
                ? { ...video, deleting: true }
                : video
        );
    },
    deleteSuccess(state, id) {
        // remove deleted user from state
        state.all.items = state.all.items.filter(video => video.id !== id);
    },
    deleteAllSuccess(state) {
        state.status = {};
        state.latestVideo = null;
    },
    deleteAllFailure(state, error) {
        state.all = { error };
    },
    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        state.all.items = state.items.map(video => {
            if (video.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...videoCopy } = video;
                // return copy of user with 'deleteError:[error]' property
                return { ...videoCopy, deleteError: error };
            }

            return video;
        });
    }
};

export const video = {
    namespaced: true,
    state,
    actions,
    mutations
};
