<style scoped>
 .btn-danger {
     height: 6em;
 }
</style>
<template>
    <div>
        <h2>Add Embedded Iframe</h2>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="url">URL</label>
                <input type="text" v-model="embedded.url" v-validate="'required'" name="url" class="form-control" :class="{ 'is-invalid': submitted && errors.has('url') }" />
                <div v-if="submitted && errors.has('url')" class="invalid-feedback">{{ errors.first('url') }}</div>
            </div>
            <div class="form-group">
                <label for="buttontext">Button Text</label>
                <input type="text" v-model="embedded.buttontext" v-validate="'required'" name="buttontext" class="form-control" :class="{ 'is-invalid': submitted && errors.has('buttontext') }" />
                <div v-if="submitted && errors.has('buttontext')" class="invalid-feedback">{{ errors.first('buttontext') }}</div>
            </div>
            <div class="form-check">
                <input type="checkbox" v-model="embedded.isActive" name="isActive" class="form-check-input" :class="{ 'is-invalid': submitted && errors.has('isActive') }" />
                <label for="isActive" class="form-check-label">Is Active?</label>
                <div v-if="submitted && errors.has('isActive')" class="invalid-feedback">{{ errors.first('isActive') }}</div>
            </div>
            <br/>
            <div class="form-group">
                <button class="btn btn-primary" :disabled="status.adding">Submit</button>
                <img v-show="status.adding" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                <router-link to="/" class="btn btn-link">Cancel</router-link>
            </div>
            <span v-if="all.error" class="text-danger">ERROR: {{all.error.error}}</span>
            <br />
        </form>
        <hr/>
        <div class="current-embedded">
            <div v-if="latestEmbedded" class="clearfix">
                <h4>Current iframe</h4>
                <dl class="float-left">
                <dt>URL:</dt><dd><a v-bind:href="latestEmbedded.url">{{latestEmbedded.url}}</a></dd>
                <dt>Button Text:</dt> <dd>{{latestEmbedded.buttontext}}</dd>
                </dl>
                <button class="btn btn-danger float-right" v-on:click="deleteAction">Delete</button>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            embedded: {
                url: '',
                buttontext: '',
                isActive: true,
            },
            submitted: false
        }
    },
    computed: {
        ...mapState('embedded', ['status', 'latestEmbedded', 'all'])
    },
    created () {
        this.getLatest();
    },
    methods: {
        ...mapActions('embedded', ['add', 'getLatest', 'deleteAll']),
        handleSubmit(e) {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    console.log(this.embedded);
                    this.add(this.embedded)
                    .then(() => {
                        this.getLatest();
                        this.$forceUpdate();
                    });
                }
            });
            
        },
        deleteAction() {
            this.deleteAll();
            this.getLatest();
        }
    }
};

function checkCORS(url) {
    fetch(url).then(response => {
        let corsHeader = response.headers.get('Access-Control-Allow-Origin');
        corsHeader.toString()
    })
}
</script>