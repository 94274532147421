import { noticeService } from '../_services';

const state = {
    all: {},
    status: {},
    latestNotice: {},
    adding: false,
};

const actions = {
    getLatest({ commit }) {
        commit('getRequest');

        noticeService.getLatest().then(
            (notice) => commit('getSuccess', notice),
            (error) => commit('getFailure', error)
        );
    },

    add({ commit }, notice) {
        commit('addRequest', notice);
        state.status = { adding: true };
        noticeService.add(notice).then(
            (notice) => {
                commit('addSuccess', notice);
            },
            (error) => commit('addFailure', { error: error.toString() })
        );
    },

    deleteNotice({ commit }, id) {
        commit('deleteRequest', id);

        noticeService.delete(id).then(
            () => commit('deleteSuccess', id),
            (error) => commit('deleteFailure', { id, error: error.toString() })
        );
    },

    deleteAll({ commit }) {
        noticeService.deleteAll().then(
            () => commit('deleteAllSuccess'),
            (error) => commit('deleteAllFailure', { error: error.toString() })
        );
    },
};

const mutations = {
    getRequest(state) {
        state.all = { loading: true };
    },
    getSuccess(state, notice) {
        console.log(notice);
        state.latestNotice = notice;
        state.all = { items: notice };
    },
    getFailure(state, error) {
        state.all = { error };
    },
    addSuccess(state, notice) {
        state.status = {};
        state.all = { items: state.all.items.push(notice) };
    },
    addRequest() {},
    addFailure(state, error) {
        state.status = {};
        state.all = { error: error };
        state.error = { error };
    },
    deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map((notice) =>
            notice.id === id ? { ...notice, deleting: true } : notice
        );
    },
    deleteSuccess(state, id) {
    // remove deleted user from state
        state.all.items = state.all.items.filter((notice) => notice.id !== id);
    },
    deleteAllSuccess(state) {
        state.status = {};
        state.text = null;
    },
    deleteAllFailure(state, error) {
        state.all = { error };
    },
    deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.all.items = state.items.map((notice) => {
            if (notice.id === id) {
                // make copy of user without 'deleting:true' property
                const { ...noticeCopy } = notice;
                // return copy of user with 'deleteError:[error]' property
                return { ...noticeCopy, deleteError: error };
            }

            return notice;
        });
    },
};

export const notice = {
    namespaced: true,
    state,
    actions,
    mutations,
};
