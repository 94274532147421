import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { account } from './account.module';
import { users } from './users.module';
import { video } from './video.module';
import { quiz } from './quiz.module';
import { embedded } from './embedded.module';
import { notice } from './notice.module';
import { config } from './config.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        account,
        users,
        video,
        quiz,
        embedded,
        notice,
        config
    }
});
