<template>
  <h4 class="header-server">{{ serverType }}</h4>
</template>

<script>
export default {
    name: 'caution-element',
    data() {
       return { serverType: '' };
    },
    created() {
        var vm = this;
        vm.serverType = (document.domain.includes('thepubhub.me')) ? 'LIVE' : 'Staging';
    },
    
}
</script>

<style>
    .header-server {
        float: right;
        text-decoration-line: underline;
        font-size: 1em;
    }
</style>