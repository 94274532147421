<template>
  <div>
    <h2>Add Notice Text</h2>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="text">Notice Text</label>
        <input
          type="text"
          v-model="notice.text"
          v-validate="'required'"
          id="text"
          name="text"
          class="form-control"
          :class="{ 'is-invalid': submitted && errors.has('text') }"
        />
        <div
          v-if="submitted && errors.has('text')"
          class="invalid-feedback"
        >{{ errors.first('text') }}</div>
      </div>
      <div class="form-group">
        <label for="roomNames">Room Names</label>
        <div
          class="tooltip-right"
          data-tooltip="Case-insensitive. Supports wildcards, however wildcards will take precedence over specific room names."
        >
          <font-awesome-icon icon="question-circle" pull="right" />
        </div>
        <input
          type="text"
          v-model="notice.roomNames"
          id="roomNames"
          name="roomNames"
          class="form-control"
          :class="{ 'is-invalid': submitted && errors.has('roomNames') }"
        />
        <div
          v-if="submitted && errors.has('roomNames')"
          class="invalid-feedback"
        >{{ errors.first('roomNames') }}</div>
      </div>
      <div class="form-group">
        <label for="linkUrl">Link To</label>
        <div
          class="tooltip-right"
          data-tooltip="A relative URI starting '/' will take the user to a PubHub room. Otherwise use 'https://' for external link."
        >
          <font-awesome-icon icon="question-circle" pull="right" />
        </div>
        <input
          type="text"
          v-model="notice.linkUrl"
          v-validate="'link'"
          id="linkUrl"
          name="linkUrl"
          class="form-control"
          :class="{ 'is-invalid': submitted && errors.has('linkUrl') }"
        />
        <div
          v-if="submitted && errors.has('linkUrl')"
          class="invalid-feedback"
        >{{ errors.first('linkUrl') }}</div>
        <div class="form-check" v-if="notice.linkUrl">
          <input
            type="checkbox"
            v-model="notice.linkNewTab"
            name="linkNewTab"
            id="linkNewTab"
            class="form-check-input"
            :class="{ 'is-invalid': submitted && errors.has('linkNewTab') }"
          />
          <label for="linkNewTab" class="form-check-label">Open In New Tab?</label>
        </div>
      </div>
      <div class="form-group">
        <label for="publishAt">Publish At Specific Time</label>
        <input
          type="datetime-local"
          v-model="notice.publishAt"
          name="publishAt"
          id="publishAt"
          class="form-control"
          :class="{ 'is-invalid': submitted && errors.has('publishAt') }"
        />
      </div>
      <div class="form-group">
        <label for="unpublishAt">Unpublish At Specific Time</label>
        <input
          type="datetime-local"
          v-model="notice.unpublishAt"
          name="unpublishAt"
          id="unpublishAt"
          class="form-control"
          :class="{ 'is-invalid': submitted && errors.has('unpublishAt') }"
        />
      </div>
      <br />
      <div class="form-group">
        <button class="btn btn-primary" :disabled="status.adding">Submit</button>
        <img
          v-show="status.adding"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
        <router-link to="/" class="btn btn-link">Cancel</router-link>
      </div>
      <span v-if="all.error" class="text-danger">ERROR: {{all.error.error}}</span>
      <br />
    </form>
    <hr />
    <div class="current-notice">
      <h4 class="current-notice-title">Current Notices</h4>
      <div v-for="notice in all.items" :key="notice.id" class="clearfix notice">
        <div v-if="notice.roomNamesRegex" class="alert alert-warning">
          Warning, notices with wildcards take precedence over room names. A Notice with a Room Name
          matching {{notice.roomNamesRegex}} will not go out to that room. They'll see this one instead.
        </div>
        <dl class="float-left">
          <dt>Notice Text:</dt>
          <dd>{{notice.text}}</dd>
          <dt v-if="notice.roomNames">Room Names:</dt>
          <dd>{{notice.roomNames}}</dd>
          <dt v-if="notice.linkUrl">Links To:</dt>
          <dd>
            <a
              :href="notice.linkUrl"
              v-bind:target="notice.linkNewTab ? '_blank' : '_self'"
            >{{notice.linkUrl}}</a>
          </dd>
          <dt v-if="notice.publishAt">Publish At:</dt>
          <dd
            v-if="notice.publishAt"
          >{{new Date(notice.publishAt).toLocaleDateString(undefined)}} @ {{new Date(notice.publishAt).toLocaleTimeString(undefined)}}</dd>
          <dt v-if="notice.unpublishAt">Unpublish At:</dt>
          <dd
            v-if="notice.publishAt"
          >{{new Date(notice.unpublishAt).toLocaleDateString(undefined)}} @ {{new Date(notice.unpublishAt).toLocaleTimeString(undefined)}}</dd>
        </dl>
        <button
          class="btn btn-danger float-right notice-delete"
          @click="deleteNotice(notice.id)"
        >Delete</button>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Validator } from 'vee-validate';



export default {
    data () {
        return {
            // TODO: I think this is fucking up the form submit.
            notice: {
                text: '',
                roomNames: '',
                isActive: true,
            },
            submitted: false
        };
    },
    computed: {
        ...mapState('notice', ['status', 'latestNotice', 'all'])
    },
    created () {
        Validator.extend('link', {
            getMessage: field => 'The ' + field + ' must start with http/https or a forward-slash "/"',
            validate: value => { 
                if(!value.startsWith('http') && !value.startsWith('/')) {
                    return false;
                }

                return true;
            }
        });
        this.getLatest();
    },
    methods: {
        ...mapActions('notice', ['add', 'getLatest', 'deleteAll', 'deleteNotice']),
        handleSubmit() {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.add(this.notice)
                        .then(() => {
                            this.getLatest();
                            // TODO: This doesn't force the update, deleteAction's does.
                            this.$forceUpdate();
                            // Hack to force state update.
                            location.reload(true);
                        });
                }
            });
            
        },
        deleteAction() {
            this.deleteAll();
            this.getLatest();
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
    [data-tooltip] {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    position: relative;
    }
    [data-tooltip]::after {
    background-color: rgba(51, 51, 51, 0.9);
    border-radius: 0.3rem;
    color: #fff;
    content: attr(data-tooltip);
    font-size: 1rem;
    font-size: 85%;
    font-weight: normal;
    line-height: 1.15rem;
    opacity: 0;
    padding: 0.25rem 0.5rem;
    position: absolute;
    text-align: center;
    text-transform: none;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    visibility: hidden;
    white-space: nowrap;
    z-index: 1;
    }
    [data-tooltip].tooltip-top::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: rgba(51, 51, 51, 0.9) transparent transparent transparent;
    top: 0;
    left: 50%;
    margin-left: -0.3rem;
    }
    [data-tooltip].tooltip-top::after {
    bottom: 100%;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    }
    [data-tooltip].tooltip-bottom::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent transparent rgba(51, 51, 51, 0.9) transparent;
    bottom: 0;
    left: 50%;
    margin-left: -0.3rem;
    }
    [data-tooltip].tooltip-bottom::after {
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    }
    [data-tooltip].tooltip-left::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent transparent transparent rgba(51, 51, 51, 0.9);
    top: 0.3rem;
    right: calc(110% - 0.3rem);
    margin-top: -0.3rem;
    }
    [data-tooltip].tooltip-left::after {
    top: -0.3rem;
    right: calc(110% + 0.3rem);
    }
    [data-tooltip].tooltip-right::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent rgba(51, 51, 51, 0.9) transparent transparent;
    top: 50%;
    top: 0.3rem;
    left: calc(110% - 0.3rem);
    margin-top: -0.3rem;
    }
    [data-tooltip].tooltip-right::after {
    top: -0.3rem;
    left: calc(110% + 0.3rem);
    }
    @media (max-width: 767px) {
    [data-tooltip].tooltip-mobile::before {
        display: none;
    }
    [data-tooltip].tooltip-mobile:after {
        font-size: 1rem;
        max-width: 20rem;
        position: fixed;
        bottom: auto;
        top: 50%;
        left: 50%;
        text-align: left;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
        white-space: normal;
    }
    }
    [data-tooltip]:hover::after,
    [data-tooltip][class*='tooltip-']:hover::before {
    visibility: visible;
    opacity: 1;
    }

    .notice {
    border: solid #d3d3d3 1px;
    margin-bottom: 1em;
    padding: 15px;
    }

    .notice-delete {
    height: 100%;
    }

    .current-notice-title {
    text-align: center;
    }
</style>