/**
 * Checks if given string is youtube url.
 * @param {string} youtubeurl string to check.
 * @returns {boolean}
 */
export function getYoutubeLink(youtubeurl) {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;// eslint-disable-line max-len


    return youtubeurl.match(p) ? RegExp.$1 : false;
}