import { embeddedService } from '../_services';
import { router } from '../_helpers';


const state = {
    all: {},
    status: {},
    latestEmbedded: {},
    adding: false
};

const actions = {
    getLatest({ commit }) {
        commit('getRequest');

        embeddedService.getLatest()
            .then(
                latestEmbedded => commit('getSuccess', latestEmbedded),
                error => commit('getFailure', error)
            );
    },

    add({ commit }, embedded) {
        commit('addRequest', embedded);
        state.status = { adding: true };
        embeddedService.add(embedded)
            .then(
                embedded => {
                    commit('addSuccess', embedded);
                },
                error => commit('addFailure', { error: error.toString() })
            );
    },

    delete({ commit }, id) {
        commit('deleteRequest', id);

        embeddedService.delete(id)
            .then(
                embedded => commit('deleteSuccess', id),
                error => commit('deleteFailure', { id, error: error.toString() })
            );
    },

    deleteAll({ commit }) {
        embeddedService.deleteAll()
            .then(
                () => commit('deleteAllSuccess'),
                error => commit('deleteAllFailure', { error: error.toString() })
            );
    }
};

const mutations = {
    getRequest(state) {
        state.all = { loading: true };
    },
    getSuccess(state, latestEmbedded) {
        state.latestEmbedded = latestEmbedded[0];
    },
    getFailure(state, error) {
        state.all = { error };
    },
    addSuccess(state, embedded) {
        state.all = { items: embedded };
        state.status = {};
        state.latestEmbedded = embedded;
    },
    addRequest(state, embedded) {

    },
    addFailure(state, error) {
        state.status = {};
        state.all = { error: error };
        state.error = { error };
    },
    deleteRequest(state, id) {
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(embedded =>
            embedded.id === id
                ? { ...embedded, deleting: true }
                : embedded
        );
    },
    deleteSuccess(state, id) {
        // remove deleted user from state
        state.all.items = state.all.items.filter(embedded => embedded.id !== id);
    },
    deleteAllSuccess(state) {
        state.status = {};
        state.latestEmbedded = null;
    },
    deleteAllFailure(state, error) {
        state.all = { error };
    },
    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        state.all.items = state.items.map(embedded => {
            if (embedded.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...embeddedCopy } = embedded;
                // return copy of user with 'deleteError:[error]' property
                return { ...embeddedCopy, deleteError: error };
            }

            return embedded;
        });
    }
};

export const embedded = {
    namespaced: true,
    state,
    actions,
    mutations
};
