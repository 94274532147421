var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Add New Quiz")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.handleSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Quiz Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.quiz.quizName,
                expression: "quiz.quizName"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.submitted && _vm.errors.has("quizName")
            },
            attrs: { type: "text", name: "name", required: "required" },
            domProps: { value: _vm.quiz.quizName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.quiz, "quizName", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.submitted && _vm.errors.has("quizName")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.first("quizName")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm._l(_vm.quiz.questions, function(question, qindex) {
          return _c("div", { key: question.internalId }, [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group mb-2" }, [
                  _c("label", { attrs: { for: qindex + "question" } }, [
                    _vm._v("Question " + _vm._s(qindex + 1))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: question.questionText,
                        expression: "question.questionText"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: qindex === 0 ? "required" : false,
                        expression: "qindex === 0 ? 'required' : false"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-invalid":
                        _vm.submitted && _vm.errors.has("questionText")
                    },
                    attrs: {
                      type: "text",
                      name: qindex + "questionText",
                      required: qindex === 0 ? "required" : false
                    },
                    domProps: { value: question.questionText },
                    on: {
                      change: _vm.addQuestion,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(question, "questionText", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.submitted &&
                  _vm.errors.has(_vm.propertyNameConcat(qindex, "question"))
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          _vm._s(
                            _vm.errors.first(
                              _vm.propertyNameConcat(qindex, "question")
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                _vm._l(question.answers, function(answer, answerIndex) {
                  return _c(
                    "div",
                    {
                      key: answer.internalId,
                      class: [
                        answerIndex === 0
                          ? "form-group mb-2"
                          : "row form-group mb-2"
                      ],
                      style:
                        answerIndex === 0
                          ? {}
                          : { "margin-left": 0, "margin-right": 0 },
                      attrs: { index: answerIndex }
                    },
                    [
                      _c(
                        "label",
                        { attrs: { for: qindex + "answer" + answerIndex } },
                        [
                          _vm._v(
                            "Answer " +
                              _vm._s(qindex + 1) +
                              " - " +
                              _vm._s(answerIndex + 1)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: question.answers[answerIndex].answer,
                            expression: "question.answers[answerIndex].answer"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: answerIndex === 0 ? "required" : false,
                            expression: "answerIndex === 0 ? 'required' : false"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: qindex + "answer" + answerIndex,
                          required: answerIndex === 0 ? "required" : false
                        },
                        domProps: {
                          value: question.answers[answerIndex].answer
                        },
                        on: {
                          change: function($event) {
                            return _vm.addAnswer(qindex)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              question.answers[answerIndex],
                              "answer",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group mb-2" }, [
                  _c("label", { attrs: { for: qindex + "timeperiod" } }, [
                    _vm._v("Time Period " + _vm._s(qindex + 1))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: question.timeperiod,
                        expression: "question.timeperiod"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: qindex + "timeperiod" },
                    domProps: { value: question.timeperiod },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(question, "timeperiod", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ])
          ])
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success float-right",
              on: { click: _vm.addQuestion }
            },
            [_vm._v("Add +")]
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.handleSubmit }
              },
              [_vm._v("Submit")]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "btn btn-link", attrs: { to: "/" } },
              [_vm._v("Cancel")]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2012a643", { render: render, staticRenderFns: staticRenderFns })
  }
}