import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../home/HomePage';
import LoginPage from '../login/LoginPage';
import RegisterPage from '../register/RegisterPage';
import VideoPage from '../video/VideoPage';
import NewQuizPage from '../quiz/NewQuizPage';
import QuizListPage from '../quiz/QuizListPage';
import EditQuizPage from '../quiz/EditQuizPage';
import TestQuiz from '../quiz/TestQuiz';
import RegisterTeam from '../quiz/TestRegisterTeam';
import EmbeddedPage from '../embedded/EmbeddedPage';
import NoticePage from '../notice/NoticePage';
import UserListPage from '../user/UserListPage';
import UserUpdatePage from '../user/UserUpdatePage';
import UpdateConfigPage from '../config/UpdateConfigPage';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', component: HomePage },
        { path: '/login', component: LoginPage },
        { path: '/register', component: RegisterPage },
        { path: '/videos', component: VideoPage },
        { path: '/notice', component: NoticePage },
        { path: '/embedded', component: EmbeddedPage },
        { path: '/quiz/all', component: QuizListPage },
        { path: '/quiz/new', component: NewQuizPage },
        { path: '/quiz/team/register', component: RegisterTeam },
        { path: '/quiz/edit/:id', component: EditQuizPage },
        { path: '/quiz/test/:id', component: TestQuiz },
        { path: '/user/list', component: UserListPage },
        { path: '/user/:id', component: UserUpdatePage },
        { path: '/config', component: UpdateConfigPage },

        // otherwise redirect to home
        { path: '*', redirect: '/' },
    ],
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page.
    // Uncomment to register a first user in Local
    // const publicPages = ['/login'];
    const publicPages = ['/login', '/register'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
});
