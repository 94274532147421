var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Register")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.handleSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "firstName" } }, [_vm._v("First Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.firstName,
                expression: "user.firstName"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.submitted && _vm.errors.has("firstName")
            },
            attrs: { type: "text", name: "firstName" },
            domProps: { value: _vm.user.firstName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "firstName", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.submitted && _vm.errors.has("firstName")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.first("firstName")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "lastName" } }, [_vm._v("Last Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.lastName,
                expression: "user.lastName"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.submitted && _vm.errors.has("lastName")
            },
            attrs: { type: "text", name: "lastName" },
            domProps: { value: _vm.user.lastName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "lastName", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.submitted && _vm.errors.has("lastName")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.first("lastName")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "username" } }, [_vm._v("Username")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.username,
                expression: "user.username"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.submitted && _vm.errors.has("username")
            },
            attrs: { type: "text", name: "username" },
            domProps: { value: _vm.user.username },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "username", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.submitted && _vm.errors.has("username")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.first("username")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { htmlFor: "password" } }, [_vm._v("Password")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.password,
                expression: "user.password"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, min: 6 },
                expression: "{ required: true, min: 6 }"
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.submitted && _vm.errors.has("password")
            },
            attrs: { type: "password", name: "password" },
            domProps: { value: _vm.user.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "password", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.submitted && _vm.errors.has("password")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.first("password")))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.status.registering }
              },
              [_vm._v("Register")]
            ),
            _vm._v(" "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status.registering,
                  expression: "status.registering"
                }
              ],
              attrs: {
                src:
                  "data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              }
            }),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "btn btn-link", attrs: { to: "/login" } },
              [_vm._v("Cancel")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fe201a56", { render: render, staticRenderFns: staticRenderFns })
  }
}