<template>
    <div>
    <em v-if="quizzes.loading">Loading quizzes...</em>
        <span v-if="quizzes.error" class="text-danger">ERROR: {{quizzes.error}}</span>
        <h3>Quizzes</h3>
        <ul v-if="quizzes.items">
            <li v-for="quiz in quizzes.items" :key="quiz.id" class="list-item">
                <a v-bind:href="'/quiz/edit/'+quiz.id">{{quiz.quizName}}</a>
                <span v-if="quiz.deleting"><em> - Deleting...</em></span>
                <span v-else-if="quiz.deleteError" class="text-danger"> - ERROR: {{quiz.deleteError}}</span>
                <span v-else> <a @click="deleteQuiz(quiz.id)" class="btn btn-danger float-right">Delete</a></span>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState({
            quizzes: state => state.quiz.all
        })
    },
    created () {
        this.getAllQuizzes();
    },
    methods: {
        ...mapActions('quiz', {
            getAllQuizzes: 'getAll',
            deleteQuiz: 'deleteQuiz'
        })
    }
}
</script>

<style>
    .btn-danger {
        color: #fff!important;
    }

    .list-item { 
        height: 3em;
    }
</style>