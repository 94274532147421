var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quiz.items
    ? _c("div", [
        _vm.quiz.loading ? _c("em", [_vm._v("Loading quiz...")]) : _vm._e(),
        _vm._v(" "),
        _vm.quiz.error
          ? _c("span", { staticClass: "text-danger" }, [
              _vm._v("ERROR: " + _vm._s(_vm.quizzes.error))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c(
            "form",
            [
              _c("h3", [_vm._v(_vm._s(_vm.quiz.items.quizName))]),
              _vm._v(" "),
              _vm._l(_vm.quiz.items.questions, function(question, index) {
                return _c("div", { key: question.id, staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "h4",
                          attrs: { for: "givenAnswer" + index }
                        },
                        [
                          _vm._v(
                            _vm._s(index + 1) +
                              ". " +
                              _vm._s(question.questionText)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: question.givenAnswer,
                            expression: "question.givenAnswer"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "givenAnswer" + index },
                        domProps: { value: question.givenAnswer },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              question,
                              "givenAnswer",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v("Submit")]
              )
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-68bfe918", { render: render, staticRenderFns: staticRenderFns })
  }
}