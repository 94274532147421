<template>
    <div>
        <div v-if="all.error">
            <div class="alert alert-danger">
                <span>{{all.error}}</span>
            </div>
        </div>
        <br>
        <textarea id="jsoneditor" v-bind:value="config"></textarea>
        <button @click="save">Save</button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import JSONEditor from 'jsoneditor';
export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapState('config', ['config', 'all'])
    },
    mounted() {
        const container = document.getElementById("jsoneditor")
        const options = {}
        const editor = new JSONEditor(container, options)
        this.get();
        editor.set(this.config)
    },
    methods: {
        ...mapActions('config', ['get', 'save']),
    }
}
</script>

<style>
    #jsoneditor {
        width: 100%;
        height: 80vh;
    }
</style>