<template>
    <div>
        <h2>Add New Quiz</h2>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="name">Quiz Name</label>
                <input type="text" 
                v-model="quiz.quizName" 
                v-validate="'required'" 
                name="name" 
                class="form-control" 
                required="required"
                :class="{ 'is-invalid': submitted && errors.has('quizName') }" />
                <div v-if="submitted && errors.has('quizName')" class="invalid-feedback">{{ errors.first('quizName') }}</div>
            </div>
            <hr />
            <div v-for="(question, qindex) in quiz.questions" v-bind:key="question.internalId">
                <div class="form-row">
                    <div class="col">
                        <div class="form-group mb-2">
                            <label :for="qindex+'question'">Question {{qindex + 1}}</label>
                            <input type="text" 
                            v-model="question.questionText" 
                            v-validate="qindex === 0 ? 'required' : false" 
                            :name="qindex+'questionText'" 
                            class="form-control" 
                            :required="qindex === 0 ? 'required' : false"
                            :class="{ 'is-invalid': submitted && errors.has('questionText') }" @change="addQuestion" />
                            <div v-if="submitted && errors.has(propertyNameConcat(qindex, 'question'))" class="invalid-feedback">{{ errors.first(propertyNameConcat(qindex,'question')) }}</div>
                        </div>
                    </div>
                    <div class="col">
                        <div v-for="(answer, answerIndex) in question.answers" 
                                v-bind:key="answer.internalId" 
                                v-bind:index="answerIndex" 
                                :class="[answerIndex === 0 ? 'form-group mb-2' : 'row form-group mb-2']" 
                                :style="answerIndex === 0 ? {} : {'margin-left': 0, 'margin-right': 0 }">
                            <label :for="qindex+'answer'+answerIndex">Answer {{qindex + 1}} - {{ answerIndex + 1 }}</label>
                            <input type="text" 
                            v-model="question.answers[answerIndex].answer" 
                            v-validate="answerIndex === 0 ? 'required' : false" 
                            :name="qindex+'answer'+answerIndex" 
                            class="form-control" 
                            :required="answerIndex === 0 ? 'required' : false"
                            @change="addAnswer(qindex)" />
                        </div>
                    </div>
                        <div class="col">
                            <div class="form-group mb-2">
                                <label :for="qindex+'timeperiod'">Time Period {{qindex + 1}}</label>
                                <input type="text" 
                                v-model="question.timeperiod" 
                                :name="qindex+'timeperiod'" 
                                class="form-control" />
                            </div>
                        </div>
                    </div>

            </div>
            <div class="form-group">
                <button class="btn btn-success float-right" v-on:click="addQuestion">Add +</button>
            </div>
            <br/>
            <div class="form-group">
                <button class="btn btn-primary" v-on:click="handleSubmit">Submit</button>
                
                <router-link to="/" class="btn btn-link">Cancel</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            quiz: {
                quizName: '',
                questions: [{ questionText: '', answers:[{quizId:'', answerId: 0, answer: ''}], timeperiod: '', internalId: 0}]
            },
            submitted: false
        }
    },
    computed: {
        ...mapState('quiz', ['status'])
    },
    methods: {
        ...mapActions('quiz', ['add']),
        addQuestion() {
            event.preventDefault();
            this.quiz.questions.push(
                {questionText: '', answers: [
                    {quizId:'', answerId: 0, answer: ''}
                    ], 
                    timeperiod: '', internalId: this.quiz.questions.length});

             this.$validator.validate();
             document.getElementsByTagName("form")[0].classList.add("was-validated");
        },
        addAnswer(questionIndex) {
            this.quiz.questions[questionIndex].answers.push({quizId:'', answerId: questionIndex, answer: ''});
        },
        handleSubmit(e) {
            event.preventDefault();
            const questionIndex = this.quiz.questions.length - 1;
            // We need to remove the last question if null
            if(this.quiz.questions[questionIndex].questionText === "")
            {
                let remArr = this.quiz.questions.pop();
                console.log(this.quiz);
            }

            this.quiz.questions.forEach((question, index) => {
                console.log(question)
                    this.quiz.questions[index].answers = question.answers.filter(answer => answer.answer !== "");
            })
            this.add(this.quiz);
            // this.submitted = true;
            // this.$validator.validate().then(valid => {
            //     if (valid) {
            //         console.log(this.quiz);
            //         this.add(this.quiz);
            //     } else {
            //         console.log(this.quiz);
            //     }
            // });
        },
        propertyNameConcat(a,b) {
            return `${a}${b}`;
        }
    }
};
</script>