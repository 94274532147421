<template>
    <div>
        <h2>Add Prescribed Video</h2>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="youtubeurl">YouTube URL</label>
                <input type="text" v-model="video.youtubeurl" v-validate="'required'" name="youtubeurl" class="form-control" :class="{ 'is-invalid': submitted && errors.has('youtubeurl') }" />
                <div v-if="submitted && errors.has('youtubeurl')" class="invalid-feedback">{{ errors.first('youtubeurl') }}</div>
            </div>
            <div class="form-group">
                <label for="buttontext">Button Text</label>
                <input type="text" v-model="video.buttontext" v-validate="'required'" name="buttontext" class="form-control" :class="{ 'is-invalid': submitted && errors.has('buttontext') }" />
                <div v-if="submitted && errors.has('buttontext')" class="invalid-feedback">{{ errors.first('buttontext') }}</div>
            </div>
            <div class="form-check">
                <input type="checkbox" v-model="video.isActive" name="isActive" class="form-check-input" :class="{ 'is-invalid': submitted && errors.has('isActive') }" />
                <label for="isActive" class="form-check-label">Is Active?</label>
                <div v-if="submitted && errors.has('isActive')" class="invalid-feedback">{{ errors.first('isActive') }}</div>
            </div>
            <br/>
            <div class="form-group">
                <button class="btn btn-primary" :disabled="status.adding">Submit</button>
                <img v-show="status.adding" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                <router-link to="/" class="btn btn-link">Cancel</router-link>
            </div>
            <span v-if="all.error" class="text-danger">ERROR: {{all.error.error}}</span>
            <br />
        </form>
        <hr/>
        <div class="current-video">
            <div v-if="latestVideo" class="clearfix">
                <h4>Current video</h4>
                <dl class="float-left">
                <dt>URL:</dt><dd><a v-bind:href="latestVideo.youtubeurl" target="_blank">{{latestVideo.youtubeurl}}</a></dd>
                <dt>Button Text:</dt> <dd>{{latestVideo.buttontext}}</dd>
                <div v-if="latestVideo.youtubeInfo">
                    <div v-if="latestVideo.youtubeInfo.items[0].snippet.title">
                        <dt>YouTube Title:</dt> <dd>{{latestVideo.youtubeInfo.items[0].snippet.title}}</dd>
                    </div>
                    <div v-if="latestVideo.youtubeInfo.errors">
                        <dt>YouTube Error:</dt> <dd>{{latestVideo.youtubeInfo.errors[0].error}}</dd>
                    </div>
                </div>
                </dl>
                <button class="btn btn-danger float-right" v-on:click="deleteAction">Delete</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getYoutubeLink } from '../_helpers/utils';

export default {
    data () {
        return {
            video: {
                youtubeurl: '',
                buttontext: '',
                isActive: true,
                youtubeInfo: {},
            },
            submitted: false,
            apiKey: 'AIzaSyAEWe_4yuM2OaDMaihGSYGl_KMDcuESYpk'
        };
    },
    computed: {
        ...mapState('video', ['status', 'latestVideo', 'all'])
    },
    created () {
        this.getLatest();
    },
    methods: {
        ...mapActions('video', ['add', 'getLatest', 'deleteAll']),
        handleSubmit(e) {
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    console.log(this.video);
                    fetch(`https://www.googleapis.com/youtube/v3/videos?id=${getYoutubeLink(this.video.youtubeurl)}&key=${this.apiKey}&fields=items(id,snippet(title))&part=snippet`).
                        then(res => res.json())
                        .then(data => {
                            this.video.youtubeInfo = data;
                            console.log(data);
                            this.add(this.video).then(() => {
                                this.getLatest();
                                this.$forceUpdate();
                            });

                        });
                    
                }
            });

        },
        deleteAction() {
            this.deleteAll();
            this.getLatest();
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
    .btn-danger {
        height: 10em;
    }
</style>