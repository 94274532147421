import { userService } from '../_services';
import { router } from '../_helpers';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

const actions = {
    login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username });
    
        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                    if(!window.location.hash) {
                        window.location = window.location + '#login';
                        window.location.reload();
                    }
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    register({ dispatch, commit }, user) {
        commit('registerRequest', user);
    
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', 'Registration successful', { root: true });
                    });
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    update({ dispatch, commit }, user) {
        commit('updateRequest', user);

        userService.update(user)
            .then(
                user => {
                    commit('updateSuccess', user);

                },
                error => {
                    commit('updateFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getById({ dispatch, commit }, id) {
        commit('getByIdRequest', id);

        userService.getById(id)
            .then(
                user => {
                    commit('getByIdSuccess', user);
                },
                error => {
                    commit('getByIdFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

};

const mutations = {
    getByIdRequest(state, id) {
        console.log(id);
    },
    getByIdSuccess(state, user) {
        state.user = user;
    },
    getByIdFailure(state, error) {},
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
        if(!window.location.hash) {
            window.location = window.location + '#login';
            window.location.reload();
        }
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
        //this.$forceUpdate;
        //location.reload();
        if(!window.location.hash) {
            window.location = window.location + '#login';
            window.location.reload();
        }
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
        if(!window.location.hash) {
            window.location = window.location + '#logout';
            window.location.reload();
        }
    },
    registerRequest(state, user) {
        state.status = { registering: true };
    },
    registerSuccess(state, user) {
        state.status = {};
    },
    registerFailure(state, error) {
        state.status = {};
    },
    updateRequest(state, user) {
        state.status = { registering: true };
    },
    updateSuccess(state, user) {
        state.status = {};
    },
    updareFailure(state, error) {
        state.status = {};
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};