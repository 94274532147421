var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu-body" }, [
    _c(
      "p",
      { staticClass: "menu-body-link" },
      [
        _c(
          "router-link",
          { attrs: { to: "/register" } },
          [
            _vm._v("Add New User "),
            _c("font-awesome-icon", {
              attrs: { icon: "user-plus", pull: "right" }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "menu-body-link" },
      [
        _c(
          "router-link",
          { attrs: { to: "/user/list" } },
          [
            _vm._v("User List "),
            _c("font-awesome-icon", { attrs: { icon: "users", pull: "right" } })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "menu-body-link" },
      [
        _c(
          "router-link",
          { attrs: { to: "/videos" } },
          [
            _vm._v("Add Prescribed Content (YouTube Video) "),
            _c("font-awesome-icon", {
              attrs: { icon: "file-video", pull: "right" }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "menu-body-link" },
      [
        _c(
          "router-link",
          { attrs: { to: "/quiz/new" } },
          [
            _vm._v("Create New Quiz "),
            _c("font-awesome-icon", {
              attrs: { icon: "plus-square", pull: "right" }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "menu-body-link" },
      [
        _c(
          "router-link",
          { attrs: { to: "/quiz/all" } },
          [
            _vm._v("Quiz List "),
            _c("font-awesome-icon", { attrs: { icon: "list", pull: "right" } })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "menu-body-link" },
      [
        _c(
          "router-link",
          { attrs: { to: "/embedded" } },
          [
            _vm._v("Add Embedded Content "),
            _c("font-awesome-icon", {
              attrs: { icon: "crop-alt", pull: "right" }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "menu-body-link" },
      [
        _c(
          "router-link",
          { attrs: { to: "/notice" } },
          [
            _vm._v("Add/Update Notice Text "),
            _c("font-awesome-icon", {
              attrs: { icon: "quote-left", pull: "right" }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "menu-body-link" },
      [
        _c(
          "router-link",
          { attrs: { to: "/config" } },
          [
            _vm._v("Update Config File "),
            _c("font-awesome-icon", { attrs: { icon: "cogs", pull: "right" } })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9fa035b4", { render: render, staticRenderFns: staticRenderFns })
  }
}