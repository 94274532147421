<style scoped>
    .menu-body {
        background-color: #e9ecef;
        color: #212529;
        min-height: 100vh;
        padding: 25px 0 0 25px;
    }

    .menu-body-link {
        padding: 0 0 10px 0;
        width: 85%;
    }

    .router-link-active {
        text-decoration: underline;
    }

    .router-link-active::after {
        float: right;
        margin-top: -5px;
        font-size: 1.5rem;
        clear: both;
        content: '⯈';
    }

    .router-link-active > svg {
        display: none;
    }
</style>
<template>
    <div class="menu-body">
        <p class="menu-body-link">
            <router-link to="/register">Add New User <font-awesome-icon icon="user-plus" pull="right" /></router-link>
        </p>
        <p class="menu-body-link">
            <router-link to="/user/list">User List <font-awesome-icon icon="users" pull="right" /></router-link>
        </p>
        <p class="menu-body-link">
            <router-link to="/videos">Add Prescribed Content (YouTube Video) <font-awesome-icon icon="file-video" pull="right" /></router-link>
        </p>
        <p class="menu-body-link">
            <router-link to="/quiz/new">Create New Quiz <font-awesome-icon icon="plus-square" pull="right" /></router-link>
        </p>
        <p class="menu-body-link">
            <router-link to="/quiz/all">Quiz List <font-awesome-icon icon="list" pull="right" /></router-link>
        </p>
        <p class="menu-body-link">
            <router-link to="/embedded">Add Embedded Content <font-awesome-icon icon="crop-alt" pull="right" /></router-link>
        </p>
        <p class="menu-body-link">
            <router-link to="/notice">Add/Update Notice Text <font-awesome-icon icon="quote-left" pull="right" /></router-link>
        </p> 
        <p class="menu-body-link">
            <router-link to="/config">Update Config File <font-awesome-icon icon="cogs" pull="right" /></router-link>
        </p>          
    </div>
</template>
<script>
export default {
    name: 'menu-element',
    props: {
        loggedIn: String
    }
};
</script>