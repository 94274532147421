<template>
    <div v-if="this.quiz.all.items">
        <h2>Edit {{ this.quiz.all.items.quizName }} Quiz</h2>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="name">Quiz Name</label>
                <input type="text" v-model="quiz.all.items.quizName" v-validate="'required'" name="name" class="form-control" :class="{ 'is-invalid': submitted && errors.has('quizName') }" />
                <div v-if="submitted && errors.has('quizName')" class="invalid-feedback">{{ errors.first('quizName') }}</div>
            </div>
            <hr />
            <div v-for="(question, index) in quiz.all.items.questions" v-bind:key="question.internalId" v-bind:index="index">
                <div class="row">
                    <div class="col">
                        <div class="form-group mb-2">
                            <label for="buttontext">Question {{index + 1}}</label>
                            <input type="text" v-model="question.questionText" v-validate="'required'" :name="index+'questionText'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('questionText') }" />
                            <div v-if="submitted && errors.has('buttontext')" class="invalid-feedback">{{ errors.first('buttontext') }}</div>
                        </div>
                    </div>
                    <div class="col">
                        <div v-for="(answer, answerIndex) in question.answers" 
                                v-bind:key="answer.internalId" 
                                v-bind:index="answerIndex" 
                                :class="[answerIndex === 0 ? 'form-group mb-2' : 'row form-group mb-2']" 
                                :style="answerIndex === 0 ? {} : {'margin-left': 0, 'margin-right': 0 }">
                            <label :for="index+'answer'+answerIndex">Answer {{index + 1}} - {{ answerIndex + 1 }}</label>
                            <input type="text" 
                            v-model="question.answers[answerIndex].answer" 
                            v-validate="answerIndex === 0 ? 'required' : false" 
                            :name="index+'answer'+answerIndex" 
                            class="form-control" 
                            :required="answerIndex === 0 ? 'required' : false"
                            @change="addAnswer(index)" />
                        </div>
                    </div>
                        <div class="col">
                            <div class="form-group mb-2">
                                <label for="buttontext">Time Period {{index + 1}}</label>
                                <input type="text" v-model="question.timeperiod" v-validate="'required'" :name="index+'timeperiod'" class="form-control" />
                            </div>
                        </div>
                    </div>

            </div>
            <div class="form-group">
                <button class="btn btn-success float-right" v-on:click="addQuestion">Add +</button>
            </div>
            <br/>
            <div class="form-group">
                <button class="btn btn-primary" v-on:click="handleSubmit">Submit</button>
                
                <router-link to="/" class="btn btn-link">Cancel</router-link>
            </div>
            <div class="row" v-if="quiz.status.success">
                <div class="col">
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>Success!</strong> {{quiz.all.items.quizName}} saved.
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" v-if="quiz.error">
                <div class="col">
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Error</strong> {{quiz.error}}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            submitted: false,
        }
    },
    computed: {
        ...mapState(['quiz'])
    },
    created () {
        this.getById(this.$route.params.id);
    },
    methods: {
        ...mapActions('quiz', ['update', 'getById']),
        addQuestion() {
            event.preventDefault();
            this.quiz.all.items.questions.push({questionText: '', answer: '', timeperiod: '', internalId: this.quiz.all.items.questions.length});
        },
        addAnswer(questionIndex) {
            this.quiz.questions[questionIndex].answers.push({quizId:'', answerId: questionIndex, answer: ''});
        },
        handleSubmit(e) {
            event.preventDefault();
            this.submitted = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.update(this.quiz.all.items);
                }
            });
        },
    }
};
</script>