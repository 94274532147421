import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { store } from './_store';
import { router } from './_helpers';
import App from './app/App';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUsers,
    faUserPlus,
    faPlusSquare,
    faCropAlt,
    faList,
    faQuoteLeft,
    faFileVideo,
    faCogs,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { version } from '../version.json';

window.version = version;

library.add(faUsers);
library.add(faUserPlus);
library.add(faPlusSquare);
library.add(faCropAlt);
library.add(faList);
library.add(faQuoteLeft);
library.add(faFileVideo);
library.add(faCogs);
library.add(faQuestionCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VeeValidate);

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App),
});
