var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.quizzes.loading ? _c("em", [_vm._v("Loading quizzes...")]) : _vm._e(),
    _vm._v(" "),
    _vm.quizzes.error
      ? _c("span", { staticClass: "text-danger" }, [
          _vm._v("ERROR: " + _vm._s(_vm.quizzes.error))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h3", [_vm._v("Quizzes")]),
    _vm._v(" "),
    _vm.quizzes.items
      ? _c(
          "ul",
          _vm._l(_vm.quizzes.items, function(quiz) {
            return _c("li", { key: quiz.id, staticClass: "list-item" }, [
              _c("a", { attrs: { href: "/quiz/edit/" + quiz.id } }, [
                _vm._v(_vm._s(quiz.quizName))
              ]),
              _vm._v(" "),
              quiz.deleting
                ? _c("span", [_c("em", [_vm._v(" - Deleting...")])])
                : quiz.deleteError
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(" - ERROR: " + _vm._s(quiz.deleteError))
                  ])
                : _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-danger float-right",
                        on: {
                          click: function($event) {
                            return _vm.deleteQuiz(quiz.id)
                          }
                        }
                      },
                      [_vm._v("Delete")]
                    )
                  ])
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5eabd25a", { render: render, staticRenderFns: staticRenderFns })
  }
}