<style>
    body {
        color: #343a40;
    }

    .admin-chome {
        display: flex;
        flex-direction: column;
    }

    .admin-main {
        /* Take the remaining height */
        flex-grow: 1;

        /* Layout the left sidebar, main content and right sidebar */
        display: flex;
        flex-direction: row;
        min-height: 100vh;
    }

    .admin-sidebar {
        width: 27%;
    }

    .admin-page {
        flex-grow: 1;
        margin: 40px 30px 0 30px;
    }
</style>
<template>
    <div class="admin-chrome">
        <header>    
            <header-element v-bind:loggedIn="JSON.stringify(loggedIn)"></header-element>
            <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
        </header>
        <main class="admin-main">
            <!-- Left sidebar -->
            <nav class="admin-sidebar" v-if="loggedIn">
                <menu-element v-bind:loggedIn="loggedIn"></menu-element>
            </nav>
            <!-- Main content -->
            <article class="admin-page">
                <router-view></router-view>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MenuElement from '../menu/MenuElement';
import HeaderElement from '../header/HeaderElement';
export default {
    name: 'app',
    computed: {
        ...mapState({
            alert: state => state.alert,
            loggedIn: () => localStorage.getItem('user')
        })
    },
    state: {
        loggedIn: window.loggedIn
    },
    methods: {
        ...mapActions({
            clearAlert: 'alert/clear' 
        })
    },
    watch: {
        $route (){
            this.clearAlert();
        }
    },
    components: {
        'menu-element': MenuElement,
        'header-element': HeaderElement,
    },
};
</script>