import { quizService } from '../_services';
import { router } from '../_helpers';


const state = {
    all: {},
    status: {},
    latestVideo: {},
    adding: false
};

const actions = {
    getAll({ commit }) {
        commit('getAllRequest');

        quizService.getAll()
            .then(
                quizzes => commit('getAllSuccess', quizzes),
                error => commit('getAllFailure', error)
            );
    },
    getById({ commit }, id) {
        quizService.get(id)
            .then(
                quizzes => commit('getByIdSuccess', quizzes),
                error => commit('getByIdFailure', error)
            );
    },
    getByIdWithTeam({ commit }, id, teamId) {
        quizService.getWithTeam(id, teamId)
            .then(
                quizzes => commit('getByIdSuccess', quizzes),
                error => commit('getByIdFailure', error)
            );
    },
    add({ commit }, quiz) {
        commit('addRequest', quiz);
        state.status = { adding: true };
        quizService.add(quiz)
            .then(
                quiz => {
                    commit('addSuccess', quiz);
                },
                error => commit('addFailure', { error: error.toString() })
            );
    },

    update({ commit }, quiz) {
        state.status = { adding: true };
        quizService.update(quiz)
            .then(
                quiz => {
                    commit('updateSuccess', quiz);
                },
                error => commit('updateFailure', { error: error.toString() })
            );
    },

    deleteQuiz({ commit }, id) {
        commit('deleteRequest', id);

        quizService._delete(id)
            .then(
                quiz => commit('deleteSuccess', id),
                error => commit('deleteFailure', { id, error: error.toString() })
            );
    },

    submitQuiz({ commit }, quiz) {
        state.status = { marking: true };
        quizService.markQuiz(quiz)
            .then(
                quiz => commit('submitQuizSuccess', quiz),
                error => commit('submitQuizFailure', { error: error.toString() })
            );
    },

    submitAnswer({ commit }, answer) {
        state.status = { marking: true };
        quizService.markAnswer(answer)
            .then(
                quiz => commit('submitAnswerSuccess'),
                error => commit('submitAnswerFailure', error)
            );
    },

    registerTeam({ commit }, team) {
        commit('registerTeamRequest');
        quizService.registerTeam(team)
            .then(
                team => commit('registerTeamSuccess', team),
                error => commit('registerTeamFailure', error)
            );
    }
};

const mutations = {
    getAllRequest(state) {
        state.all = { loading: true };
    },
    getAllSuccess(state, quizzes) {
        state.all = { items: quizzes };
    },
    getAllFailure(state, error) {
        state.all = { error };
    },
    getByIdRequest(state) {
        state.all = { loading: true };
    },
    getByIdSuccess(state, quizzes) {
        state.all = { items: quizzes };
    },
    getByIdFailure(state, error) {
        state.all = { error };
    },
    getRequest(state) {
        state.all = { loading: true };
    },
    getSuccess(state, latestQuiz) {
        state.latestQuiz = latestQuiz[0];
    },
    getFailure(state, error) {
        state.all = { error };
    },
    addSuccess(state, quiz) {
        state.all = { items: quiz };
        state.status = {};
    },
    addRequest(state, quiz) {

    },
    addFailure(state, error) {
        state.status = {};
        state.all = { error: error };
        state.error = { error };
    },
    updateSuccess(state, quizzes) {
        state.all = { items: quizzes };
        state.status = {success: true };
    },
    updateFailure(state, error) {
        state.status = {};
        state.all = { error: error };
        state.error = { error };
    },
    deleteRequest(state, id) {
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(quiz =>
            quiz.id === id
                ? { ...quiz, deleting: true }
                : quiz
        );
    },
    deleteSuccess(state, id) {
        // remove deleted user from state
        state.all.items = state.all.items.filter(quiz => quiz.id !== id);
    },
    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        state.all.items = state.items.map(quiz => {
            if (quiz.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...quizCopy } = quiz;
                // return copy of user with 'deleteError:[error]' property
                return { ...quizCopy, deleteError: error };
            }

            return quiz;
        });
    },
    submitQuizSuccess(state, quiz) {
        state.quiz = quiz;
    },
    submitQuizFailure(state, error) {
        state.error = error;
    },
    submitAnswerSuccess(state) {
        
    },
    submitAnswerFailure(state, error) {
        state.error = error;
    },
    registerTeamRequest(state, team) {
        state.status = { registering: true };
    },
    registerTeamSuccess(state, team) {
        state.status = {};
        state.team = team;
    },
    registerTeamFailure(state, error) {
        state.status = {};
        state.error = error;
    }
};

export const quiz = {
    namespaced: true,
    state,
    actions,
    mutations
};
