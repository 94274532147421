<template>
    <div v-if="quiz.items">
    <em v-if="quiz.loading">Loading quiz...</em>
        <span v-if="quiz.error" class="text-danger">ERROR: {{quizzes.error}}</span>
        <div>
            <form>
                <h3>{{quiz.items.quizName}}</h3>
                <div class="row" v-for="(question, index) in quiz.items.questions" v-bind:key="question.id">
                    <div class="col">
                        <div class="form-group">
                            <label :for="'givenAnswer'+index" class="h4">{{index + 1}}. {{question.questionText}}</label>
                            <input type="text" v-model="question.givenAnswer" :name="'givenAnswer'+index" class="form-control">
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary" v-on:click="handleSubmit">Submit</button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState('quiz', {
            quiz: state => state.all
        })
    },
    created () {
        if(this.$route.query.teamId)
        {
            this.getByIdWithTeam(this.$route.params.id, this.$route.query.teamId)
        } else {
        this.getById(this.$route.params.id);
        }
    },
    methods: {
        // Mark answers method in Actions when we get there.
        ...mapActions('quiz', ['getById', 'getByIdWithTeam', 'submitQuiz']),
        handleSubmit(e) {
            event.preventDefault();
            if(this.$route.query.teamId)
            {  
                this.quiz.items.teamId = this.$route.query.teamId;
            }
            this.quiz.items.quizId = this.$route.params.id;
            this.submitQuiz(this.quiz.items);
        }
    }
}
</script>

<style>

</style>